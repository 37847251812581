import { HttpClient } from "../HttpClient";
/**
 * * Get Contents Claim Status List
 */
export const GetContentsClaimStatusList = async () => {
  try {
    const { data } = await HttpClient.get("api/contents/ClaimStatusList");
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
