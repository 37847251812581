<template>
  <VVVLayout>
    <MDBAlert color="primary" static class="mb-4 mb-md-3 px-4 py-3" style="font-size: 14.5px;">
      <i class="fas fa-info-circle me-2"></i>
      Our dashboard is in <strong>BETA</strong>. Please use your <strong>Google Sheet</strong> for claims if there are
      glitches. Thank you!
    </MDBAlert>
    <!-- dashboard metrics -->
    <div id="DashboardMetrics" class="gap-2 mb-2">
      <MDBCard v-for="counter in countersName" :key="counter.id" class="counter flex-grow-1 mb-lg-0 mb-2">
        <MDBCardBody class="px-3 py-0 pb-1">
          <div class="d-flex justify-content-center align-items-center" v-if="loading">
            <MDBSpinner grow />
          </div>
          <template v-else>
            <div class="overlay">
              <MDBBtn class="btn" @click="toSpecificClaimsList(counter.id)">
                View List
                <MDBIcon icon="arrow-right" class="fas fa-fw " />
              </MDBBtn>
            </div>
            <div class="d-flex align-items-center">
              <MDBIcon :icon="counter.icon" class="fas fa-fw ms-1"
                :style="{ fontSize: '20px', color: counter.color }" />
              <div class="ms-3 text-start" style="display: flex; flex-direction: column;">
                <span style="font-size: 22px;font-weight: bold;">{{ getCount(counter.id) }}</span>
                <span class="text-muted text-nowrap" style="font-size: 14px;">{{ counter.name }}</span>
              </div>
            </div>
          </template>
        </MDBCardBody>
      </MDBCard>
    </div>
    <!-- recently added claims -->
    <MDBCard id="RecentlyAdded" class="gap-2 mt-2">
      <MDBCardHeader class="text-left justify-content-between align-items-center px-4 py-3 bg-white d-block d-md-flex">
        <strong class="text-black" style="font-size: 14px;">Recently Added Claims</strong>
        <MDBBtn class="btn btn-primary p-3 py-2" @click="toClaimsList">
          View Full List
          <MDBIcon icon="arrow-right" class="fas fa-fw" />
        </MDBBtn>
      </MDBCardHeader>
      <MDBCardBody class="overflow-auto justify-content-start px-4 py-3">
        <MDBTable hover class="align-middle">
          <thead class="table-dark" style="z-index: 0;">
            <tr>
              <th scope="col" class=" align-middle text-left" v-if="isAdminOrSuperAdmin">
                Distributor Name
              </th>
              <th v-for="(header, i) in tableHeaders" :key="i" scope="col" class=" align-middle text-left">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody class="overflow-auto">
            <template v-if="isLoading">
              <tr>
                <td :colspan="isAdminOrSuperAdmin ? '7' : '6'" class="text-center">
                  <MDBSpinner />
                </td>
              </tr>
            </template>
            <template v-else>
              <template v-if="contentsList.length === 0">
                <tr>
                  <td :colspan="isAdminOrSuperAdmin ? '7' : '6'" class="text-left">No data found.</td>
                </tr>
              </template>
              <template v-else v-for="(contents, k) in contentsList" :key="k">
                <tr>
                  <td v-if="isAdminOrSuperAdmin" class="text-truncate" style="max-width: 180px;">
                    {{ contents.userId ? contents.userFirstName + ' ' + contents.userLastName : '-' }}
                  </td>
                  <td>{{ contents.youtubeChannel }}</td>
                  <td class="text-truncate" style="max-width: 180px;"><a :href="contents.youtubeUrl" target="_blank"
                      class="text-left">{{ contents.youtubeUrl }}</a>
                  </td>
                  <td>{{ contents.timeStamp }}</td>
                  <td>{{ contents.assetLabel }}</td>
                  <td class="text-truncate" style="max-width: 180px;"><a :href="contents.dropboxLink" target="_blank"
                      class="text-left">{{ contents.dropboxLink }}</a>
                  </td>
                  <td>{{ parseDateLong(contents.dateAdded) }}</td>
                </tr>
              </template>
            </template>
          </tbody>
        </MDBTable>
      </MDBCardBody>
    </MDBCard>
  </VVVLayout>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBCardHeader, MDBSpinner, MDBTable, MDBBtn, MDBIcon, MDBAlert } from "mdb-vue-ui-kit";
import { onMounted, ref, defineExpose, computed } from "vue";
import { GetContentsClaimStatusList } from "@/services/Contents/GetContentsClaimStatusList";
import VVVLayout from "@/components/VVV/VVVLayout.vue";
import { useUserDetailStore } from "@/store/userDetailStore";
import { storeToRefs } from "pinia";
import { parseDateLong } from "@/helpers/parseDate";
import { useRouter } from "vue-router";
import { useTitle } from "@vueuse/core";
import { GetContentsList } from "@/services/Contents/GetContentsList";

const loading = ref(false);
const countersName = ref([
  { id: 1, name: "Active Claims", icon: "check-circle", color: '#28a745' },
  { id: 2, name: "Invalid Claims", icon: "times-circle", color: '#dc3545' },
  { id: 3, name: "Duplicate Claims", icon: "copy", color: '#fd7e14' },
  { id: 4, name: "Resolved Issues", icon: "tools", color: '#007bff' },
  { id: 5, name: "Submitted Claims", icon: "file-upload", color: '#6c757d' },
  { id: 6, name: "For Further Review", icon: "exclamation-triangle", color: '#d5d500' },
  // { id: 7, name: "Awaiting for 100 views", icon: "eye", color: '#ffc107' } // Remove temporarily
  { id: 8, name: "Awaiting for 1 view", icon: "hourglass-half", color: '#ffc107' }
]);
const response = ref([]);

const userDetailStore = useUserDetailStore();
const { isAdmin, isSuperAdmin } = storeToRefs(userDetailStore);
const isAdminOrSuperAdmin = computed(() => isAdmin.value || isSuperAdmin.value);

const tableHeaders = computed(() => {
  return [
    "Channel Name",
    "Youtube URL",
    "Timestamp",
    "Asset Label",
    "Clip URL",
    "Date Added",
  ];
});

const router = useRouter();
const toClaimsList = () => {
  router.push("/ClaimsList");
};
const toSpecificClaimsList = (id) => {
  router.push(`/ClaimsList?claimStatus=${id}`);
};

const isLoading = ref(false);
const contentsList = ref([]);
const contentsListPagination = ref({});

onMounted(() => {
  useTitle("Dashboard | Very Viral Visuals");
  loadBreakdown();
  fetchContentsList()
});

const fetchContentsList = async () => {
  isLoading.value = true;
  try {
    const response = await GetContentsList({ pageNumber: 1, rowsPerPage: 5 });
    contentsList.value = response.data;
    contentsListPagination.value = response.pagination;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const loadBreakdown = async () => {
  loading.value = true;
  response.value = await GetContentsClaimStatusList();
  loading.value = false;
};

const getCount = (id) => {
  const item = response.value.find(counter => counter.id === id);
  return item ? formatNumberWithCommas(item.count) : 0;
};
function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

defineExpose({ loadBreakdown });
</script>

<style scoped>
#DashboardMetrics {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table th {
  background-color: var(--accent);
}

.btn {
  font-size: 10px;
  padding: 6.5px 10px;
}

td {
  text-wrap: nowrap;
  font-size: 13px;
}

th {
  text-wrap: nowrap;
  max-width: 30%;
  font-size: 13px;
}

@media (max-width: 992.98px) {
  #DashboardMetrics {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767.98px) {
  #DashboardMetrics {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575.98px) {
  #DashboardMetrics {
    display: flex;
    flex-direction: column;
  }
}

.btn-primary {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  gap: 5px;
}

.spinner-grow {
  color: var(--accent);
}

.card {
  border-radius: 16px;
}

.card-header {
  background: var(--accent);
  color: white;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  text-align: center;
}

.card-title {
  color: var(--primary);
}

#RecentlyAdded {
  flex: 1 1 auto;
  overflow: auto;
}

@media screen and (max-width: 767px) {
  #RecentlyAdded {
    flex: 0 0 auto;
  }
}

.counter {
  min-height: 80px;
}

.overlay {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: rgb(0 0 0 / 25%);
}

.overlay .btn {
  background-color: var(--primary);
  color: white;
}

.counter:hover .card-body .overlay {
  display: grid;
  place-content: center;
}

td {
  text-wrap: nowrap;
  width: fit-content;
  text-align: left;
}

th {
  text-wrap: nowrap;
  width: fit-content;
  text-align: left;
}
</style>
